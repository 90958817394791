"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var data = [
    {
        id: 2,
        name: "mossos",
        title: "Oposición Mossos d'Esquadra 2025",
        description: "En iOpos Academia haremos que una de las 850 plazas convocadas sea para ti.",
        button: "¡La mejor preparación y los mejores resultados!",
        href: ["/oposiciones/mossos-desquadra-2025/", "/oposiciones/mossos-desquadra/"],
        image: "/sections/front-mossos",
        imageAlt: "Mossos d'Esquadra",
        background: "/sections/bg-mossos",
        backgroundAlt: "Mossos d'Esquadra",
        enabled: true,
    },
    {
        id: 3,
        name: "gub",
        title: "Oposición Guardia Urbana Barcelona 2025",
        description: "Empieza tu preparación desde hoy mismo y asegúrate una plaza para la siguiente convocatoria.",
        button: "¡La mejor preparación y los mejores resultados!",
        href: [
            "/oposiciones/guardia-urbana-barcelona-2025/",
            "/oposiciones/guardia-urbana-de-barcelona/",
            "/oposiciones/guardia-urbana-barcelona/",
        ],
        image: "/sections/front-gub",
        imageAlt: "Guàrdia Urbana de Barcelona 2025",
        background: "/sections/bg-gub",
        backgroundAlt: "Guàrdia Urbana de Barcelona",
        enabled: true,
    },
    {
        id: 1,
        name: "pl",
        title: "Oposiciones a Policía Local 2025",
        description: "Cada año convocan plazas en casi todas las policías locales de Catalunya, escoge las que más te gusten y vamos a por ello.",
        button: "¡Más del 85% de nuestros alumnos consiguen plaza!",
        href: ["/oposiciones/policia-local-2025/", "/oposiciones/policia-local/"],
        image: "/sections/front-pol",
        imageAlt: "Policía Local 2024 / 2025",
        background: "/sections/bg-pol",
        backgroundAlt: "Policía Local 2024 / 2025",
        enabled: true,
    },
    {
        id: 4,
        name: "ispc",
        title: "38ª Promoción ISPC",
        description: "Gana seguridad y confianza y pasa la escuela sin problemas.",
        button: "¡Ya estás a punto de ser policía, la calle te espera!",
        href: ["/oposiciones/38a-promocion-ispc/"],
        image: "/sections/front-ispc",
        imageAlt: "ISPC",
        background: "/sections/bg-ispc",
        backgroundAlt: "ISPC",
        enabled: true,
    },
    {
        id: 5,
        name: "pmm",
        title: "Policía Municipal de Madrid 2025",
        description: "Empieza tu preparación desde hoy mismo y asegúrate una plaza para la siguiente convocatoria.",
        button: "¡La mejor preparación y los mejores resultados!",
        href: ["/oposiciones/policia-municipal-madrid-2025/"],
        image: "/sections/front-pmm",
        imageAlt: "PMM",
        background: "/sections/bg-pmm",
        backgroundAlt: "PMM",
        enabled: true,
    },
];
exports.default = data;
